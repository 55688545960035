import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { ThunkAction } from 'redux-thunk';

import { setPurchase } from 'core/store/billing/actions';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { REDIRECT_TO_CHROME, doRedirectToChrome } from 'core/utils/redirect-to-chrome';
import { Purchase } from 'core/interfaces/billing';

import { ROUTES, SUBSCRIPTION } from 'src/constants/routes';
import { type HabioFlowStep } from 'src/constants/screens';

import { processEmail } from '../profile/actions';

import { selectFlow } from './selectors';

import { routingSlice } from '.';

export const { setStep, setCompleted, setStartRoute } = routingSlice.actions;

export const init = createAsyncThunk(
  `${routingSlice.name}/init`,
  async (_, { dispatch, getState }) => {
    const { redirectToChrome } = remoteConfigSelector(getState());
    const flow = selectFlow(getState());

    if (redirectToChrome === REDIRECT_TO_CHROME.ENABLED) {
      doRedirectToChrome(redirectToChrome);
    }

    const params = queryString.parse(window.location.search);

    if (params.completed !== undefined) {
      dispatch(setCompleted(true));
    }

    if (params.purchased !== undefined) {
      dispatch(setPurchase({} as Purchase));
    }

    if (params.email) {
      dispatch(processEmail(params.email as string));
    }

    const {
      routing: { isCompleted },
    } = getState() as any;

    const startRoute = params.create_account === null ? ROUTES.CREATE_ACCOUNT : !isCompleted ? ROUTES[flow[0].toUpperCase() as HabioFlowStep] : await dispatch(getRouteAfterFlow());
    dispatch(setStartRoute(startRoute));

    return Promise.resolve(false);
  }
);



export const getRouteAfterFlow = (): ThunkAction<string, any, unknown, any> => (dispatch, getState) => {
  const {
    billing: { purchased },
    remoteConfig: { presummaryStack },
  } = getState();

  if (purchased) {
    return SUBSCRIPTION.SUCCESS
  } else if (presummaryStack?.length) {
    return SUBSCRIPTION.PRESUMMARY;
  } else {
    return SUBSCRIPTION.MAIN;
  }
};