import React from 'react';
import classNames from 'classnames';

import classes from '../../style.module.scss';

interface Props {
  textLineThrough: string;
  trialPrice: string;
  className?: string;
}
const TrialPrice: React.FC<Props> = ({
  textLineThrough,
  trialPrice,
  className,
}) => (
  <p className={classNames(classes.aboutItemText, className)}>
    <span className={classes.lineThrough}>{textLineThrough}</span>
    <span> {trialPrice}</span>
  </p>
);

export default TrialPrice;