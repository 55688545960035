import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectsTypes, PROJECTS_TYPES } from '@web-solutions/core/constants/general';

export interface AppState {
  paymentProject: projectsTypes;
  loaded: boolean;
  pending: boolean;
  appLink: string;
}


const initialState: AppState = {
  paymentProject: PROJECTS_TYPES.QUIZ,
  loaded: false,
  pending: false,
  appLink: '',
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setAppLink: (state, action: PayloadAction<string>) => {
      state.appLink = action.payload;
    }
  }
})


export const blacklist = ['loaded', 'pending'];

export { slice as appSlice };

