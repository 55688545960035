import React from 'react';
import _find from 'lodash/find';
import classNames from 'classnames';

import { Line } from '../Line';

import { getCurrentStepFiltered } from '../utils';

import classes from './style.module.scss';

interface Props {
  flow: string[][];
  currentPage: string;
  pagesWithoutProgress?: string[];
}

export const Steps: React.FC<Props> = ({
  flow,
  currentPage,
  pagesWithoutProgress,
}) => {
  const numberOfCurrentBlock = flow.findIndex((block) => block.includes(currentPage));

  return (
    <div className={classes.points_wrapper}>
      <div className={classes.process__points}>
        {flow.map((block, index) => {
          const filtered = block.filter((page) => !_find(pagesWithoutProgress, page))

          const percent =
            index < numberOfCurrentBlock
              ? 100
              : index > numberOfCurrentBlock
                ? 0
                : Math.ceil((getCurrentStepFiltered(block, block.indexOf(currentPage), filtered) + 1) * (100 / block.length));

          return (
            <div className={classes.process} key={index}>
              <div className={
                classNames(classes.process__points__step_point,
                  { [classes.process__points__step_point_current]: index === numberOfCurrentBlock },
                  { [classes.process__points__step_point_done]: index < numberOfCurrentBlock })
              }
              ></div>
              <Line percent={percent} />
            </div>
          );
        })}
        <div className={classes.process__points__step_point}></div>
      </div>
    </div>
  );
};
