import { useSelector } from 'react-redux';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Survey } from '@web-solutions/core/containers/survey'

import { Theme } from '@web-solutions/core/constants/theme';

import { selectSubscription } from '../../slice';
import { SubscriptionStatus } from '../../types';

import { useNavigateManage } from '../../hooks/use-navigate-next-screen';

export const ManageSurvey: React.FC = () => {
  const { link, enableClose } = useRemoteConfig().manageSurvey;
  const { navigateNextScreen } = useNavigateManage();
  const subscription = useSelector(selectSubscription);
  const status = subscription?.status as SubscriptionStatus.ACTIVE | SubscriptionStatus.TRIAL

  const handleSurveyFinish = () => {
    navigateNextScreen();
  }

  return (
    <Survey
      theme={Theme.dark}
      link={link[status]}
      enableClose={enableClose}
      analyticsCategory="manage_survey"
      onSurveyFinish={handleSurveyFinish}
    />
  );
};
