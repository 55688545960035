import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import { initializeApp as initFirebase } from 'firebase/app'

import Analytics from '@web-solutions/module-analytics';
import Attribution from '@web-solutions/module-attribution';

import { PROJECTS_TYPES } from 'core/constants/general';
import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';
import { CookieClear } from 'core/containers/cookie-clear';

import { APP_LINKS, } from 'src/constants/general';

import { auth } from 'src/store/profile/actions';
import { getMagnusToken } from 'src/store/app/utils';

import packageInfo from '../package.json';

import { initLocalization } from './localization';
import configureStore from './store';
import { loadRemoteConfig, load } from './store/app/actions';

import 'react-toastify/dist/ReactToastify.min.css';
import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

const App = (AppRouter: React.FC) => {
  const pType = window.location.pathname.split('/')[1];
  const { store, persistor } = configureStore();

  initLocalization();
  Analytics.init(getMagnusToken(), packageInfo.version);
  Attribution.init(APP_LINKS);

  if (pType !== PROJECTS_TYPES.TERMINATE) {
    store.dispatch(load());
    store.dispatch(auth());
  } else {
    store.dispatch(loadRemoteConfig());
  }

  return (
    <StrictMode>
      <Provider store={store}>
        <CookieClear>
          <ThemeContextProvider value={{ theme: Theme.light }}>
            <PersistGate loading={null} persistor={persistor}>
              <ToastContainer position="bottom-center" autoClose={4000} closeOnClick />
              <AppRouter />
            </PersistGate>
          </ThemeContextProvider>
        </CookieClear>
      </Provider>
    </StrictMode>
  );
};

initFirebase({
  apiKey: "AIzaSyANDOuxMm07gmwMDKkDlj_tsYnDhzvJGF4",
  authDomain: window.location.hostname === 'localhost' ? 'sub-stage.habio.me' : window.location.hostname,
  databaseURL: "https://habio-f7ca0.firebaseio.com",
  projectId: "habio-f7ca0",
  storageBucket: "habio-f7ca0.appspot.com",
  messagingSenderId: "244109455886",
  appId: "1:244109455886:web:a3a037e18a35def05f425f",
  measurementId: "G-95NL2RY6ZC"
});

export const render = (AppRouter: React.FC) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(App(AppRouter));
};
