import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Habio';

export const PROJECT_SUPPORT_SUBJECT = '[Habio WEB] ';

export const SUPPORT_EMAIL = 'habio.app@support-team.app';

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: 'https://habio.go.link/p',
  ADJUST_TOKEN: 'uxh87rl',
};
