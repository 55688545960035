import FACE_1X from './face.webp';
import FACE_2X from './face@2x.webp';
import FACE_3X from './face@3x.webp';

export const IMAGES = {
  FACE: {
    src: FACE_1X,
    srcSet: `${FACE_1X}, ${FACE_2X} 2x, ${FACE_3X} 3x`,
  },
};
