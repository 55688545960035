import i18next, { type Resource } from 'i18next';
import { TransProps } from 'react-i18next';

import { t as baseT, T as BaseT } from '@web-solutions/module-localization';

const resources: Resource = {
  de: require('./de.json'),
  en: require('./en.json'),
  'es-mx': require('./es-mx.json'),
  es: require('./es.json'),
  fr: require('./fr.json'),
  'pt-br': require('./pt-br.json'),
  pt: require('./pt.json'),
  ja: require('./ja.json'),
  ar: require('./ar.json'),
};

const ns = '@web-solutions/face-reading';

export function initLocalization(overriding?: any) {
  for (const k in resources) {
    i18next.addResourceBundle(k, ns, resources[k], true, false);
    if (overriding?.[k]) {
      i18next.addResourceBundle(k, ns, overriding[k], true, true);
    }
  }
}

type TProps = Omit<TransProps<any>, "i18n" | "i18nKey">

export const T = ({ k, tOptions, ...other }: { k: string, } & TProps) => {
  return <BaseT
    k={k}
    tOptions={tOptions}
    //@ts-ignore
    ns={ns}
    {...other}
  />
}

export function t(key: string, options?: object): string {
  return baseT(ns + ':' + key, options);
}
