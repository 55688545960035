import i18next from 'i18next';

import request from 'core/utils/network';

const HABITS = '/habits';
const USERS_HABITS_ADD_MANY = `${HABITS}/users-habits/add-many`;
const ALL_HABITS = `${HABITS}/all-habits-by-lang`;

export function getAllHabits() {
  const lang = i18next.language;
  return request.get(`${ALL_HABITS}?lang_id=${lang}`);
}

export function createUserHabits(data) {
  return request.post(USERS_HABITS_ADD_MANY, data);
}
