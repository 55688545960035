import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { T, t, tm } from '@web-solutions/module-localization';

import { type PriceTypes } from '@web-solutions/core/constants/remote-config';

import { getCurrencySymbols, } from '@web-solutions/react-billing/utils/prices';
import { createVATPrice } from '@web-solutions/core/utils/vat';

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import TrialsLeft, { TrialsLeftType } from 'core/payment/components/trials-left';
import TrialPrice from 'core/payment/base/product-info/components/trial-price';
import { ProductDetails } from 'core/store/billing/selectors';
import { COUNTRY_VAT, PriceAfterTrialType } from 'core/payment/constants';

import classes from '../../style.module.scss';
import { VatPrice } from '../vat-price';

interface PricesProps {
  product: ProductDetails;
  priceText: string;
}

const prevAmountTypes: Record<PriceTypes, 'prevPriceAmount' | 'prevWeekPriceAmount' | 'prevDayPriceAmount'> = {
  today: 'prevPriceAmount',
  week: 'prevWeekPriceAmount',
  day: 'prevDayPriceAmount',
}

export const vatPriceTypes: Record<PriceTypes, 'vatPrice' | 'vatWeekPrice' | 'vatDayPrice'> = {
  today: 'vatPrice',
  week: 'vatWeekPrice',
  day: 'vatDayPrice',
}

export const vatTypes: Record<PriceTypes, 'vat' | 'weekVat' | 'dayVat'> = {
  today: 'vat',
  week: 'weekVat',
  day: 'dayVat',
}

const tKey = 'core.payment_popup';

export const Prices: React.FC<PricesProps> = ({
  product,
  priceText,
}) => {

  const countryCode = useSelector((state: any) => state?.billing?.countryCode);

  const {
    showPriceDiscount,
    promocodeOffer,
    showAfterTrialPrice,
    totalPriceType,
    displayVAT,
    configVAT,
    priceAfterTrialType,
  } = useRemoteConfig();

  const {
    title,
    currency,
    textLineThrough,
    period,
    discount,
    isTrial,
    trialDays,
    trialPrice,
    aboutTrialText,
    savedPrice,
    vatTrial,
    vatTrialPrice,
    promocode,
  } = product;

  const isSimpleVAT = configVAT.view === 'simple';

  const displayTaxes = displayVAT && !!COUNTRY_VAT[countryCode];

  const amountLineThrough = textLineThrough ? product[prevAmountTypes[totalPriceType]] : '';

  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);
  const textLineThroughByPeriod = `${currencySymbol}${amountLineThrough}${currencySuffix}`

  const totalPriceKey = displayTaxes && !isSimpleVAT ? 'total_item' : isTrial ? 'today' : totalPriceType;

  const vatPrice = product[vatPriceTypes[totalPriceType]];

  const vat = product[vatTypes[totalPriceType]];

  const { price: priceLineThrough } = countryCode ? createVATPrice({ amount: amountLineThrough, currency, countryCode }) : { price: '' };

  const priceString = displayTaxes && isSimpleVAT ? priceText : vatPrice;

  let todayPrice = !isTrial ? priceString : trialPrice;

  if (displayTaxes && !isSimpleVAT && isTrial) {
    todayPrice = vatTrialPrice
  }

  const vatPriceText = isTrial ? trialPrice : priceText;

  return <>
    <div className={classes.block}>
      {showPriceDiscount && (
        <div className={classes.row}>
          <p className={classes.aboutItemText}>
            {tm(title, '')}
          </p>
          <div className={classes.leftColumn}>
            <span className={classNames(classes.lineThrough, classes.disabled)}>
              {tm(textLineThrough, '', product)}
            </span>
          </div>
        </div>
      )}
      {showPriceDiscount && !!discount && !!savedPrice && (
        <div className={classes.row}>
          <p className={classes.aboutItemText}>
            <b>{t(`${tKey}.personal_discount`, { discount: discount })}</b>
          </p>
          <div className={classes.leftColumn}>
            <b>{tm(savedPrice, '', product)}</b>
          </div>
        </div>
      )}
      {isTrial && (
        <>
          <div className={classes.row}>
            <p className={classes.aboutItemText}>
              {tm(aboutTrialText, `${tKey}.trial`, { days: trialDays })}
            </p>
            <div className={classes.leftColumn}>
              <TrialsLeft type={TrialsLeftType.TEXT} />
              <TrialPrice
                textLineThrough={!showPriceDiscount ? tm(textLineThrough, '', product) : ''}
                trialPrice={displayVAT ? vatTrialPrice : trialPrice}
              />
            </div>
          </div>
          {showAfterTrialPrice && (
            <div className={classes.row}>
              <p className={classes.aboutItemText}>{t(`${tKey}.after`)}</p>
              <p className={classes.aboutItemText}>
                {
                  priceAfterTrialType === PriceAfterTrialType.WEEK
                    ? t(`${tKey}.after_trial.${period}`, product)
                    : `${priceText}/${t(`${tKey}.price_texts.${period}`)}`
                }
              </p>
            </div>
          )}
        </>
      )}
      {promocode?.discount && promocode?.discountPrice && promocodeOffer?.showPaymentModalDiscount &&
        <div className={classes.promo_discount}>
          <p>
            {t(`${tKey}.promocode.discount`, { discount: promocode?.discount })}
          </p>
          <p className={classes.discount_price}>
            {`-${currencySymbol}${promocode?.discountPrice}${currencySuffix}`}
          </p>
        </div>
      }
      <div className={classes.row}>
        <p className={classes.aboutItemBlackText}>
          <T
            k={`${tKey}.total.${totalPriceKey || 'today'}`}
            components={{ span: <span className={classes.smallText} /> }}
          />
        </p>
        <p className={classes.aboutItemBlackText}>
          {!isTrial && !!textLineThrough && !showPriceDiscount &&
            <span className={classes.lineThrough}>
              {displayTaxes ? priceLineThrough : textLineThroughByPeriod}
            </span>
          }
          {todayPrice}
        </p>
      </div>
      <VatPrice
        priceText={vatPriceText}
        vat={isTrial ? vatTrial : vat}
        vatPrice={isTrial ? vatTrialPrice : vatPrice}
      />
    </div>
  </>
}