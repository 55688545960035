//@ts-ignore
import Billing from '@web-solutions/module-billing';

//@ts-ignore
import { request as requestBillingApi } from '@web-solutions/module-billing/api/network';

import { getActualMailingFlag } from '@web-solutions/base-app/utils/mailing';

import type { ProfileState } from '@web-solutions/base-app/store/profile';

const billingApi = requestBillingApi(process.env.REACT_APP_API_BILLING_URL);

export const updateProfile = async <T extends Record<string, unknown> = {}>(profile: Partial<ProfileState> & T) => {
  try {
    billingApi.post('/profile', {
      email: profile.email,
      is_unsubscriber: getActualMailingFlag(profile.is_unsubscriber!),
    }, {
      headers: Billing.getHeaders()
    });
  } catch (err) {
    console.log('[ERROR UPD PROFILE]', err);
    throw err
  }
}