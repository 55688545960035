import React from 'react';
import { useSelector } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { InjectedPaymentMethod } from 'core/constants/remote-config';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import PayPalBtn from 'core/payment/components/paypal/native';
import { ModeGooglePay } from 'core/constants/billing';
import { useShowPayPal, useShowApplePay } from 'core/hooks/use-show-payment-method';

import { selectInjectedPaymentMethod } from 'core/store/billing/selectors';

import classes from './style.module.scss';

const payPalButtonStyle = {
  height: 55,
};

interface Props {
  activeProduct: any,
  onPayPalSubmit: (data: any) => void;
  onCardClick: () => void;
}

const InjectedPaymentMethodButton: React.FC<Props> = ({
  activeProduct,
  onPayPalSubmit,
  onCardClick,
}) => {
  const { defaultInjectedPaymentMethod, modeGooglePay } = useSelector(remoteConfigSelector);
  const injectedPaymentMethod = useSelector(selectInjectedPaymentMethod);
  const activeItem = injectedPaymentMethod || defaultInjectedPaymentMethod;
  const showPayPal = useShowPayPal();
  const showApplePay = useShowApplePay();
  const showGooglePay = modeGooglePay !== ModeGooglePay.DISABLED;

  const handleApplePayClick = () => {
    Analytics.trackEvent('injected_apple_pay', 'click');

    const appleButton = (document.querySelector('apple-pay-button') || document.querySelector('#apple-pay') || document.querySelector('button[label="apple"]')) as HTMLButtonElement | null;

    if (appleButton) {
      try {
        appleButton.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleGooglePayClick = () => {
    Analytics.trackEvent('injected_google_pay', 'click');

    const googleButton = document.querySelector<HTMLButtonElement>('[aria-label="Google Pay"]');

    if (googleButton) {
      try {
        googleButton.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleCardClick = () => {
    Analytics.trackEvent('injected_card', 'click');
    onCardClick();
  }

  const handlePayPalSubmit = (formData: any) => {
    Analytics.trackEvent('injected_paypal', 'click');
    onPayPalSubmit(formData);
  };

  return (
    <div className={classes.button}>
      {
        InjectedPaymentMethod.PAYPAL === activeItem && showPayPal && (
          <PayPalBtn
            product={activeProduct}
            onSubmit={handlePayPalSubmit}
            className={classes.paypal}
            style={payPalButtonStyle}
          />
        )
      }
      {
        InjectedPaymentMethod.APPLEPAY === activeItem && showApplePay && (
          <div className={classes.default} onClick={handleApplePayClick} />
        )
      }
      {
        InjectedPaymentMethod.GOOGLEPAY === activeItem && showGooglePay && (
          <div className={classes.default} onClick={handleGooglePayClick} />
        )
      }
      {
        InjectedPaymentMethod.CARD === activeItem && (
          <div
            className={classes.default}
            onClick={handleCardClick}
          />
        )
      }
    </div>
  );
};

export default InjectedPaymentMethodButton;
