import type { FC, ComponentProps, ReactNode } from 'react'
import classNames from 'classnames'

import { Text } from '@web-solutions/core/ui-elements'

import classes from './style.module.scss'

interface PriceProps extends ComponentProps<'div'> {
  activePrice: string;
  notActivePrice: string | ReactNode;
  discount: string
  activePriceFontSize?: number,
  notActivePriceFontSize?: number,
}

export const Price: FC<PriceProps> = ({ activePrice, notActivePrice, discount, className, activePriceFontSize, notActivePriceFontSize, ...props }) =>
  <div className={classNames(classes.wrapper, className)} {...props}>
    <Text style={activePriceFontSize ? { fontSize: `${activePriceFontSize}px` } : {}} className={classNames(classes.price, classes.activePrice)}>
      {activePrice}
    </Text>
    <Text style={notActivePriceFontSize ? { fontSize: `${notActivePriceFontSize}px` } : {}} className={classNames(classes.price, classes.notActivePrice)}>
      {notActivePrice}
      <span className={classNames(classes.price, classes.discount)}>
        {discount}
      </span>
    </Text>
  </div>

