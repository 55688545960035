import { getStatus as getFaceApiStatus } from "./faceapi";
import { getStatus as getMeiapipeStatus } from "./mediapipe";

export function getStatus() {
  return getFaceApiStatus() || getMeiapipeStatus();
}

export const checkIfBoxInStaticOval = ({
  shape,
  t,
  h,
  l,
  w,
}
  :
  { shape: HTMLElement, t: number, h: number, w: number, l: number }
) => {
  const y = t + h / 2
  const x = l + w / 2
  const coords = shape.getBoundingClientRect()

  const centerY = coords.y + coords.height / 2
  const y1 = centerY - coords.height / 2
  const y2 = centerY + coords.height / 2

  const isInShape = x > coords.left + coords.width * 0.4 && x < coords.left + coords.width + coords.width * -0.4 && y < y2 + y2 * -0.1 && y > y1 + h / 2

  if (isInShape) {
    //@ts-ignore
    shape.style = `border-color: #63ADA4;`;
  } else {
    //@ts-ignore
    shape.style = `border-color: #C56554;`;
  }
  return isInShape
}