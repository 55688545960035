import Lottie from 'lottie-react-web';

import rate from '../../animations/Rate.json';

import classes from '../../style.module.scss';

interface RateProps {
  isFaceDetected: boolean;
}

export const RateAnimation: React.FC<RateProps> = ({ isFaceDetected }) => {
  return <div className={classes.rate_wrap}>
    {isFaceDetected ?
      <Lottie
        width={'125px'}
        height={'75px'}
        speed={isFaceDetected ? 1 : 0}
        style={{
          margin: 0,
        }}
        options={{ animationData: rate }}
      />
      :
      null
    }
  </div>
}