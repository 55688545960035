import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux'

import { ProgressDescriptionType } from '@web-solutions/face-reading/types';

//@ts-ignore
import { Title } from 'core/ui-elements';

import { selectFaceImgDims } from '../../../slice';

import { t } from '../../../localization';

import { ColumnsProgressDescription } from '../descriptions/columns';
import { DefaultProgressDescription } from '../descriptions/default';

import { CustomStages } from '../descriptions/default';

import classes from './style.module.scss';

const tKey = 'analyzing';

interface Props {
  progress: number,
  progressDescriptionType?: ProgressDescriptionType,
  customStages?: CustomStages,
}

export const Progress: React.FC<Props> = ({ progress, progressDescriptionType, customStages }) => {
  const columnsMode = progressDescriptionType === ProgressDescriptionType.COLUMNS;
  const { isFullScreen } = useSelector(selectFaceImgDims);

  return (
    <div className={classNames(classes.progress__wrapper, { [classes.full_mode]: isFullScreen })}>
      {progress > 0 ? (
        <>
          <div className={classNames(classes.progress_line_wrap, { [classes.in_row]: columnsMode })}>
            <Title className={classes.progress__number}>{progress}%</Title>
            <div className={classes.line}>
              <div className={classes.inactive} />
              <div className={classes.active} style={{ width: `${progress}%` }} />
            </div>
          </div>
          {columnsMode ?
            <ColumnsProgressDescription progress={progress} />
            :
            <DefaultProgressDescription progress={progress} titleClassName={isFullScreen && classes.full_title} customStages={customStages} />
          }
        </>
      ) : (
        <div className={classes.progress_line_wrap}>
          <Title className={classes.progress__number}>{t(`${tKey}.title`)}</Title>
          <div className={classes.line}>
            <div className={classes.inactive} />
            <div className={classNames(classes.active, classes.animated)} />
          </div>
        </div>
      )}
    </div>
  );
};
