export const HABITS: Record<string, number> = {
  'drink': 28,
  'write': 1,
  'plan': 26,
  'read': 23,
  'meditate': 17,
  'exercise': 33,
  'practice': 19,
  'sleep': 2,
};

export const ROUTINES: Record<string, number> = {
  'adhd_mind': process.env.REACT_APP_ENV === 'stage' ? 138 : 170,
  'focus': process.env.REACT_APP_ENV === 'stage' ? 150 : 171,
  'detox': 172,
  'morning': 174,
  'things': 173,
  'wellness': 175,
  'peace': 176,
  'sleep': 177
};

export const BASE_COURSE_VERSION = 13;
