import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Navigate, Routes, Route } from 'react-router-dom';

import { Preloader } from '@web-solutions/core/ui-elements';
import { useHeight } from '@web-solutions/core/hooks/use-height';

import { selectPending, selectReady, init, useSliceDispatch } from './slice';

import { ROUTES } from './constants/routes';

import { ManageMainPage } from './screens/main';
import { SelectReasonScreen } from './screens/reason';
import { ManagePlansPage } from './screens/plans';
import { CancelSuccessPage } from './screens/cancel-success';
import { OfferCheapProductPage } from './screens/offer-cheap-product';
import { OfferFreePeriodPage } from './screens/offer-free-period';
import { FarewellOfferPage } from './screens/farewell-offer';
import { ManageBenefitsPage } from './screens/benefits';
import { TerminateSubscription } from './screens/terminate';
import { ProgressPage } from './screens/progress';
import { EmailCancelPage } from './screens/email-cancel';
import { FreeGiftCancelPage } from './screens/free-gift-cancel';
import { ManageSurvey } from './screens/survey';

import { ManageContext, ManageContextProvider } from './hooks/use-manage-context';

import classes from './style.module.scss';

const SCREENS = {
  [ROUTES.MAIN]: <ManageMainPage />,
  [ROUTES.REASON]: <SelectReasonScreen />,
  [ROUTES.PLANS]: <ManagePlansPage />,
  [ROUTES.BENEFITS]: <ManageBenefitsPage />,
  [ROUTES.OFFER_CHEAP_PRODUCT]: <OfferCheapProductPage />,
  [ROUTES.SINGLE_OFFER_CHEAP_PRODUCT]: <OfferCheapProductPage />,
  [ROUTES.OFFER_FREE_PERIOD]: <OfferFreePeriodPage />,
  [ROUTES.FAREWELL_OFFER]: <FarewellOfferPage />,
  [ROUTES.CANCEL_SUCCESS]: <CancelSuccessPage />,
  [ROUTES.PROGRESS]: <ProgressPage />,
  [ROUTES.TERMINATE_SUBSCRIPTION]: <TerminateSubscription />,
  [ROUTES.EMAIL_CANCEL]: <EmailCancelPage />,
  [ROUTES.FREE_GIFT_CANCEL]: <FreeGiftCancelPage />,
  [ROUTES.SURVEY]: <ManageSurvey />,
};

interface ManageProps extends ManageContext {
  isApiReady: boolean,
  isLoadedApp: boolean,
}

export const Manage: React.FC<ManageProps> = ({
  isApiReady,
  isLoadedApp,
  cancelSuccessContent,
  successModalContent,
  freeGiftCancelContent,
  emailCancelContent,
  benefitsContent,
  offerCheapProductContent,
  withFarewellOffer,
  offerFreePeriodContent,
  farewellOfferContent,
  currentPlanContent,
  terminateFeatures,
  progressContent,
  mainContent,
}) => {
  const dispatch = useSliceDispatch();

  const isPending = useSelector(selectPending);
  const isReady = useSelector(selectReady);

  const containerHeight = useHeight();

  useEffect(() => {
    if (isApiReady && isLoadedApp) {
      dispatch(init());
    }
  }, [dispatch, isApiReady, isLoadedApp]);

  const manageContext = {
    cancelSuccessContent,
    successModalContent,
    freeGiftCancelContent,
    benefitsContent,
    emailCancelContent,
    offerCheapProductContent,
    offerFreePeriodContent,
    farewellOfferContent,
    currentPlanContent,
    withFarewellOffer,
    terminateFeatures,
    progressContent,
    mainContent,
  }

  if (!isReady) {
    return <Preloader />;
  }

  return (
    <>
      {(isPending)
        ? <Preloader />
        : null}
      <div className={classes.bg_wrap}>
        <div style={{ minHeight: containerHeight }} className={classes.wrapper}>
          <Suspense fallback={<div />}>
            <ManageContextProvider value={manageContext}>
              <BrowserRouter>
                <Routes>
                  {Object.values(ROUTES).map((r, i) => (
                    <Route key={i} path={r} element={SCREENS[r]} />
                  ))}
                  <Route path={'*'} element={
                    <Navigate to={{
                      pathname: ROUTES.MAIN,
                      search: window.location.search,
                    }} />}
                  />
                </Routes>
              </BrowserRouter>
            </ManageContextProvider>
          </Suspense>
        </div>
      </div>
    </>
  );
}
