import { request as r } from './network';

function request(headers = {}) {
  return r(`${process.env.REACT_APP_API_BILLING_URL || process.env.REACT_APP_API_BASE_URL}/payments`, headers);
}

/**
 * @typedef {{
 *  data: Array,
 *  meta: {
 *    include: Array,
 *    custom: Array,
 *    pagination: {
 *      total: number,
 *      count: number,
 *      per_page: number,
 *      current_page: number,
 *      total_pages: number,
 *      links: Object
 *    }
 *  }
 * } Response
 */

/**
 * Basically payment_system_project and payment_system_type for all methods are enums. For Reflectio payment_system_project=default, payment_system_type=recurly
 */
const API = {
  /**
   *
   * @param {{ payment_system_project: string, payment_system_type: string }} params
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  getPublicKey(params, headers) {
    return request(headers).get('/config', { params });
  },

  /**
   *
   * @param {{
   *  search: string|undefined, // You can use search=external_id:1,2,3&searchFields=external_id:in to get specific products
   *  searchFields: string|undefined,
   *  payment_system_project:string,
   *  payment_system_type:string
   * }} params
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  getPrices(params, headers) {
    return request(headers).get('/products', { params });
  },

  /**
   *
   * @param {{
   *  product_id:string,
   *  order_description:string,
   *  payment_system_project:string,
   *  payment_system_type:string
   * }} params
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  createOrder(body, headers) {
    return request(headers).post('/orders', body);
  },

  createPayPalOrder(body, headers) {
    return request(headers).post('/orders/paypal-via-solidgate', body);
  },

  createCheckout(body, headers) {
    return request(headers).post('/checkouts', body);
  },

  /**
   *
   * @param {{
   *  email: string|undefined,
   *  payment_system_project: string,
   *  payment_system_type: string,
   *  domain: string|undefined,
   *  ip: string|undefined,
   *  user_agent: string|undefined,
   *  fbc: string|undefined,
   *  fbp: string|undefined
   * }} body
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  createCustomer(body, headers) {
    return request(headers).post('/customers', body);
  },

  /**
   *
   * @param {{ search: string }} params*
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  getActualizedSubscriptions(headers) {
    return request(headers).get('/subscriptions/actualized');
  },

  /**
   *
   * @param {{ search: string }} params*
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  getAllSubscriptions(headers) {
    return request(headers).get('/subscriptions?all=true');
  },

  /**
   *
   * @param {{
   *  mail_link: string,
   *  price_id: string,
   *  token_id: string|undefined,
   *  payment_method_id: string|undefined,
   *  trial_period_days: string|undefined,
   *  invoice_price_id: string|undefined,
   *  payment_system_project: string,
   *  payment_system_type: string,
   *  domain: string|undefined,
   *  ip: string|undefined,
   *  user_agent: string|undefined,
   *  fbc: string|undefined,
   *  fbp: string|undefined
   * }} body
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  createSubscription(body, headers) {
    return request(headers).post('/subscriptions', body);
  },

  /**
   *
   * @param {{
   *   payment_system_project: string,
   *   payment_system_type: string,
   *   product_id: string,
   *   success_url: string,
   *   fail_url: string,
   * }} body
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  subscribeOnOneClick(body, headers) {
    return request(headers).post('/subscriptions/create-new-subscription-by-card-token', body);
  },

  createApplePayOrder(body, headers) {
    return request(headers).post('/apple-pay', body);
  },

  /**
   *
   * @param {{ email: string }} body
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  cancelSubscription(body, headers) {
    return request(headers).post('/subscriptions/request-cancel', body);
  },

  /**
   *
   * @param {*} params
   * @param {{ token: string, email: string, sig: string }} headers
   * @returns { Promise<Response> }
   */
  submitCancelSubscription(params, headers) {
    return request(headers).get('/subscriptions/cancel', { params });
  },

  /**
   *
   * @param {{
   *  amount: number,
   *  description: string,
   *  currency: string,
   *  success_url: 'string',
   *  fail_url: 'string',
   *  product_code: 'string',
   * }} body
   * @param {*} headers
   * @returns { Promise<Response> }
   */
  createOneClickPayment(body, headers) {
    return request(headers).post('/one-time-payment', body);
  },

  getOneTimePurchases(headers) {
    return request(headers).get('/one-time-payment');
  },

  /**
   *
   * @param {{ search: string }} params*
   * @param {*} headers
   * @returns { Promise<Response> }
   */

  getPaymentMethod(headers) {
    return request(headers).get('/one-time-payment/get-payment-method');
  },

  /**
   *
   * @param {{
  *  subscriptionId: string,
  *  planCode: string,
  * }} body
  * @param {*} headers
  * @returns { Promise<Response> }
  */
  changePlan(body, headers) {
    return request(headers).post('/subscriptions/change-plan', body);
  },
  /**
     *
     * @param {{
  *  email: string,
  * }} body
  * @param {*} headers
  * @returns { Promise<Response> }
  */
  sendAccessEmail(body, headers) {
    return request(headers).post('/subscriptions/send-access-mail', body);
  },

  giveProduct(body, headers) {
    return request(headers).post('/one-time-payment/free', body);
  },
};

export default API;
