import {
  FaceDetector,
  FilesetResolver,
  FaceDetectorResult,
  BoundingBox,
} from "@mediapipe/tasks-vision";

import { checkIfBoxInStaticOval } from ".";

let faceDetection: FaceDetector & {
  dims?: any,
  isStatic?: any,
  results?: any,
  faceMask?: any,
  box?: BoundingBox,
};

let status: null | 'ready' | 'error' = null;

export function getStatus() {
  return status;
}

export const initializefaceDetector = async () => {
  try {
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.7/wasm"
    );
    faceDetection = await FaceDetector.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
        delegate: "CPU"
      },
      runningMode: 'IMAGE'
    });
    status = 'ready';
  }
  catch (ex) {
    console.warn(ex);
    status = 'error';
  }
};

function onResults(results: FaceDetectorResult) {
  faceDetection.results = true;
  faceDetection.faceMask = document.getElementById('face-shape');
  if (faceDetection.faceMask) {
    let box = results.detections?.[0]?.boundingBox;
    let b = faceDetection?.box;
    if (box) {
      if (!b
        || Math.abs(b.originX - box.originX) > 4
        || Math.abs(b.originY - box.originY) > 4
      ) {
        b = faceDetection.box = box;
      }

      const h = b.height;
      const w = b.width;
      const t = b.originY;
      const l = b.originX;

      if (faceDetection.isStatic) {
        if (!checkIfBoxInStaticOval({ shape: faceDetection.faceMask, l, t, w, h })) {
          faceDetection.box = undefined
        }
      } else {
        faceDetection.faceMask.style = `width: ${w}px; height: ${h}px; top: ${t}px; left: ${l}px;`;
      }
    } else {
      faceDetection.faceMask.style = '';
      faceDetection.box = undefined
    }
  }
};

export const faceSend = async (camera: any, isStatic: boolean): Promise<boolean> => {
  if (faceDetection) {
    faceDetection.isStatic = isStatic;
    if (faceDetection.results) {
      onResults(await faceDetection.detect(camera.canvas));
    } else if (faceDetection.results === undefined) {
      faceDetection.results = false;
      onResults(await faceDetection.detect(camera.canvas));
    }
  }

  return !!faceDetection?.box;
};
