import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  percent: number;
  className?: string;
  rounded?: boolean;
}

export const Line: React.FC<Props> = ({ className, percent, rounded }) => {
  return (
    <div className={classNames(classes.line, { [classes.rounded]: rounded }, className)}>
      <span style={{ width: `${percent}%` }} className={classes.line_feeling} />
    </div>
  );
};
