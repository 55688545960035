import React, { useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _merge from 'lodash/merge';
import { useSelector } from 'react-redux';

import { CardForm, PaymentSystem } from '@web-solutions/react-billing';
import { t } from '@web-solutions/module-localization';

import Analytics from '@web-solutions/module-analytics';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { selectPaymentSystem } from 'core/store/billing/selectors';
import { getButtonTitleByConfig } from 'core/utils/button-configurator';
import { ButtonPlacementType } from 'core/payment/constants';

import './style.scss';

const PaymentForm = ({
  product,
  onSubmit,
  onSuccess,
  onError,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onUserInfoChange,
  options,
  formClassName,
  fieldClassName,
  inputStyles,
  labelStyles,
  notEmptyLabelStyles,
  isSimpleView,
  color,
}) => {
  const startEnterCardForm = useRef(false);
  const completedEnterCardForm = useRef(false);

  const paymentSystem = useSelector(selectPaymentSystem);
  const { additionalFields, buttonsConfigs } = useSelector(remoteConfigSelector);
  const email = useSelector((state) => state.profile.email);
  const shippingAddressRef = useRef();
  const buttonConfig = buttonsConfigs[ButtonPlacementType.PAYMENT];
  const buttonTitle = product.isOneTimePurchase
    ? t('core.payment_popup.form.button_title')
    : getButtonTitleByConfig(buttonConfig, 'core.payment_popup.form.button_title', product);
  const buttonClassNames = classNames('form-button', 'form-button-title');

  const additionalField = useMemo(() => {
    return additionalFields?.email ? _merge(additionalFields, { email: { value: email } }) : additionalFields;
  }, [additionalFields, email]);

  const handleButtonSubmit = (formData) => {
    Analytics.trackEvent('submit_pay_button', 'click');
    onSubmit(formData);
  };

  const handleFormReady = useCallback(() => {
    Analytics.trackEvent('card_form', 'ready');
  }, []);

  const handleFormChange = useCallback((e) => {
    // TODO refactor target check
    if (!e.target || paymentSystem === PaymentSystem.SOLIDGATE) {
      if (!e.error) {
        if (!startEnterCardForm.current && !e.empty) {
          startEnterCardForm.current = true;
          Analytics.trackEvent('card_form_enter', 'start');
        } else if (!completedEnterCardForm.current && (e.complete || e.valid)) {
          completedEnterCardForm.current = true;
          Analytics.trackEvent('card_form_enter', 'completed', { brand: e.brand });
        }
      }
    }
  }, [paymentSystem]);

  const validateChildren = () => {
    return shippingAddressRef?.current?.validateFields();
  }

  return (
    <CardForm
      isSimpleView={isSimpleView}
      product={product}
      color={color}
      onReady={handleFormReady}
      onChange={handleFormChange}
      onSubmit={handleButtonSubmit}
      onSuccess={onSuccess}
      onError={onError}
      onApplePayClick={onApplePayClick}
      onApplePaySubmit={onApplePaySubmit}
      onGooglePayClick={onGooglePayClick}
      onGooglePaySubmit={onGooglePaySubmit}
      onUserInfoChange={onUserInfoChange}
      formClassName={formClassName}
      fieldClassName={fieldClassName}
      buttonClassName={buttonClassNames}
      buttonTitle={buttonTitle}
      additionalFields={additionalField}
      options={options}
      inputStyles={inputStyles}
      labelStyles={labelStyles}
      notEmptyLabelStyles={notEmptyLabelStyles}
      onValidateChildren={validateChildren}
    >
    </CardForm>
  );
};

PaymentForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onApplePayClick: PropTypes.func.isRequired,
  onApplePaySubmit: PropTypes.func.isRequired,
  onGooglePayClick: PropTypes.func.isRequired,
  onGooglePaySubmit: PropTypes.func.isRequired,
  onUserInfoChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  labelStyles: PropTypes.object,
  inputStyles: PropTypes.object,
  notEmptyLabelStyles: PropTypes.object,
};

PaymentForm.defaultProps = {
  options: null,
  inputStyles: null,
  labelStyles: null,
  notEmptyLabelStyles: null,
};

export default PaymentForm;
