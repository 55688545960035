import React, { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import html2canvas from 'html2canvas';

import { ProgressDescriptionType } from '@web-solutions/face-reading/types';

import { selectFaceImgDims, selectFaceKeyPoints } from '../slice';

import { ReactComponent as Check } from './icons/check-min.svg';

import { Progress } from './components/progress';
import { FaceReadingPreview } from './components/preview';

import { CustomStages } from './components/descriptions/default';

import classes from './style.module.scss';

interface FaceReadingAnalyzingProps {
  analyzingDuration: number;
  onDone: (result: { imgUrl: string | null | undefined }) => void;
  progressDescriptionType?: ProgressDescriptionType;
  customStages?: CustomStages,
}

export const FaceReadingAnalyzing: React.FC<FaceReadingAnalyzingProps> = ({
  analyzingDuration,
  onDone,
  progressDescriptionType,
  customStages
}) => {
  const previewRef = React.useRef<HTMLDivElement>(null);
  const [imgUrl, setImgUrl] = React.useState<string | null | undefined>(undefined);

  const finalProgressStep = 100;

  const pace = analyzingDuration / finalProgressStep;

  const dataFetched = !!useSelector(selectFaceKeyPoints);

  const { isFullScreen } = useSelector(selectFaceImgDims);

  const [progress, setProgress] = useState(0);

  const isFinished = progress === finalProgressStep;

  useEffect(() => {
    if (dataFetched) {
      const t = setInterval(() => {
        setProgress((p) => Math.min(p + 1, finalProgressStep));
      }, pace);
      return () => clearInterval(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);

  useEffect(() => {
    if (progress === 70) {
      html2canvas(previewRef.current!)
        .then(canvas => {
          return canvas.toDataURL('image/jpeg', 0.8);
        })
        .catch(() => {
          setImgUrl(null);
          return null;
        })
        .then((dataUrl) => {
          setImgUrl(dataUrl);
        })
    }
  }, [progress]);

  useEffect(() => {
    if (isFinished && imgUrl !== undefined) {
      onDone({ imgUrl });
    }
  }, [isFinished, onDone, imgUrl]);

  return (
    <div className={classNames(classes.wrapper, { [classes.full_mode]: isFullScreen, [classes.finished]: isFinished })}>
      <FaceReadingPreview
        ref={previewRef}
        animated
        className={classNames(classes.container, { [classes.transformed]: progressDescriptionType === 'columns' && isFullScreen })}
        progressDescriptionType={progressDescriptionType}
      />
      <div className={classes.check_mark}>
        <div className={classes.check_mark_third} />
        <div className={classes.check_mark_second} />
        <div className={classes.check_mark_first}>
          <Check />
        </div>
      </div>
      <div className={classes.footer}>
        <Progress
          progress={progress}
          progressDescriptionType={progressDescriptionType}
          customStages={customStages}
        />
      </div>
    </div>
  );
};
