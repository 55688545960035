import { selectedAnswersSelector } from 'core/store/quiz/selectors';

import { QUIZ_NAMES } from '../../../../constants/screens';


export const improveAreasSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_CONSEQUENCES);
});

export const investingTimeSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_INVEST_TIME);
});

export const habitsSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_MEETING_FAIRY);
});

export const routinesSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_ROUTINE);
});

export const procrastinationSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_PROCRASTINATION);
});

export const causesToStruggleSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_CAUSES_TO_STRUGGLE)
});

export const StrugglingTimeSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_STRUGGLING_WITH_FOCUSING)
});

export const after30DaysSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_AFTER_30_DAYS)
});

export const afterMonthSelector = ((state: any) => {
  return selectedAnswersSelector(state, QUIZ_NAMES.QUIZ_AFTER_MONTH)
});
