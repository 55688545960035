import UAParser from 'ua-parser-js';
import queryString from 'query-string';

import Analytics from '@web-solutions/module-analytics';

export enum REDIRECT_TO_CHROME {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ON_CLICK = 'onclick',
}

export function doRedirectToChrome(mode: REDIRECT_TO_CHROME, link?: string) {
  const r = new UAParser().getResult();
  if (['Facebook', 'Instagram'].includes(r?.browser?.name || '')) {
    const q = queryString.parseUrl(link || window.location.href);
    link = queryString.stringifyUrl({ url: q.url, query: { ...q.query, idfm: undefined } });

    if (r?.os?.name === 'Android') {
      Analytics.trackEvent('redirect', 'to_chrome', { mode });
      window.location.href = `intent:${link}#Intent;end`;
    } else if (r?.os?.name === 'iOS') {
      Analytics.trackEvent('redirect', 'to_chrome', { mode });
      const l = "googlechrome-x-callback://x-callback-url/open/?url=" + encodeURIComponent(link);
      if (mode === REDIRECT_TO_CHROME.ENABLED) {
        window.location.href = l;
      } else {
        window.open(l);
      }
    }
  }
}
