import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

import { ImageSource } from 'core/interfaces/images';

let _theme: string;

export function getTheme() {
  return _theme;
}

export function setTheme(t: string) {
  _theme = t;
}

async function cacheImages(srcArray: ImageSource[]) {
  return Promise.all(
    srcArray.map(
      (s) =>
        new Promise((res, rej) => {
          const img = new Image();

          s = s.themes?.[_theme] || s;

          if (s.srcSet) {
            img.srcset = s.srcSet;
            //@ts-ignore
            img.src = s.src;
          } else {
            //@ts-ignore
            img.src = s.src || s;
          }

          img.onload = res;
          img.onerror = rej;
        }),
    ),
  );
}

export async function cacheImagesCascade(srcArray: ImageSource[]) {
  return Promise.all(
    _map(
      _chunk(srcArray, 2),
      (g, index) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            cacheImages(g).then(resolve, reject);
          }, 200 + 400 * index);
        }),
    ),
  );
}

export async function cacheImagesCascadeByTheme(imgs: { [theme: string]: ImageSource[] }) {
  return cacheImagesCascade(imgs[_theme]);
}
