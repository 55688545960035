import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import Analytics from '@web-solutions/module-analytics';
import { useEmail } from '@web-solutions/core/hooks/use-email';
import { Input, Button, Preloader } from '@web-solutions/core/ui-elements';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { t } from '../../localization';

import { processEmail, requestCancel, useSliceDispatch } from '../slice';

import { TerminateFooter } from '../components/footer';

import { ROUTES } from '../routes';
import { selectPending, selectEmail } from '../slice';

import { TerminateSurvey } from '../survey';

import { ReactComponent as IconCheck } from './icons/icon-check.svg';

import classes from './style.module.scss';

const tKey = 'terminate.main';

export const TerminateMainScreen: React.FC = () => {
  const dispatch = useSliceDispatch();
  const refInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const params = queryString.parse(window.location.search);
  const [value, isValid, setValue] = useEmail(params.email || '');
  const isPending = useSelector(selectPending);
  const email = useSelector(selectEmail);
  const [isApply, setApply] = useState(!!email);
  const [showInputError, setShowInputError] = useState(false);
  const { link } = useRemoteConfig().manageSurvey;
  const [showSurvey, setShowSurvey] = useState(!!link.terminate);

  useEffect(() => {
    Analytics.trackEvent('terminate_main', 'open');
    refInput.current?.focus();
  }, []);

  useEffect(() => {
    if (isValid) {
      dispatch(processEmail(value));
      setApply(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.target.value;

    if (val !== email) {
      setShowInputError(false);
      setApply(false);
    }

    setValue(val);
  };

  const handleSubmitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (isApply || !isValid) {
      setApply(false);
      return;
    }

    try {
      Analytics.trackEvent('terminate_main', 'apply', { email: value });
      setValue(value);
      if (value !== email) {
        dispatch(processEmail(value));
      }
      setApply(true);
    } catch (error) {
      setShowInputError(true);
    }
  };

  const handleButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    Analytics.trackEvent('terminate_main', 'submit', { email: value });
    dispatch(requestCancel())
      .unwrap()
      .then(() => {
        Analytics.trackEvent('terminate_main', 'sent');
        navigate(ROUTES.EMAIL_SENT);
      })
      .catch((error: any) => {
        console.warn(error);
        const message = error?.data?.message ? error.data.message : t(`${tKey}.404`);
        Analytics.trackEvent('terminate_main', 'error', { message: message });
        toast(message);
      });
  };

  const handleSurveyFinish = () => {
    setShowSurvey(false)
  }

  return (
    <div>
      {showSurvey
        ? <TerminateSurvey onSurveyFinish={handleSurveyFinish} />
        :
        <div className={classes.wrap}>
          {isPending && <Preloader />}
          <div className={classes.top}>
            <div className={classes.titles}>
              <h1 className={classes.titles_appname}>{t('core.appName', { ns: '' })}</h1>
              <h2 className={classes.titles_title}>{t(`${tKey}.title`)}</h2>
              <p className={classes.titles_text}>{t(`${tKey}.text`)}</p>
            </div>

            <form className={classes.form} onSubmit={handleSubmitForm}>
              <div className={classes.form_field}>
                <Input
                  type="email"
                  name="email"
                  ref={refInput}
                  onChange={handleChange}
                  value={value}
                  className={classNames(classes.form_input, {
                    [classes.form_input__invalid]: showInputError,
                  })}
                  placeholder={t(`${tKey}.placeholder`)}
                />
                {showInputError && (
                  <p className={classes.form_field_error_text}>{t(`${tKey}.email_invalid`)}</p>
                )}
              </div>
              <button
                type="submit"
                className={classes.form_button}
                disabled={!isValid}
              >
                {isApply
                  ? (
                    <div className={classes.form_button_check}>
                      <IconCheck width={20} height={15} />
                    </div>
                  )
                  : (
                    <div className={classes.form_button_title}>
                      <span>{t('_apply', { ns: '' })}</span>
                    </div>
                  )}
              </button>
            </form>
          </div>

          <div className={classes.bottom}>
            <Button
              onClick={handleButtonClick}
              type="button"
              className={classNames(classes.bottom_button, {
                [classes.isDisabled]: !isApply,
              })}
              disabled={!isApply}
              title={t(`${tKey}.button_title`)}
            />
          </div>
          <TerminateFooter />
        </div>
      }
    </div>
  );
};
