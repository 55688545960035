export const getActualMailingFlag = (isUnsubscriber: boolean | null | number): number => {
  if (typeof isUnsubscriber === 'number' && !Number.isNaN(isUnsubscriber)) {
    return isUnsubscriber
  }

  if (isUnsubscriber === null) {
    return 1
  }

  return isUnsubscriber ? 1 : 0
}
