export type FaceCapture = {
  stressScanEnabled: boolean,
  isAutoTakeAfterScan: boolean,
  withHeartRateLine: boolean,
  duration: number,
  mode: 'basic' | 'fullMode' | 'staticFullMode',
  faceLib: 'faceapi' | 'mediapipe',
}
export enum ProgressDescriptionType {
  DEFAULT = 'default',
  COLUMNS = 'columns'
}