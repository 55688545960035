import Lottie from 'lottie-react-web';

import pulse from '../../animations/Pulse.json';

interface HeartProps {
  isFaceDetected: boolean;
  speed: number;
  className?: string
}

export const HeartAnimation: React.FC<HeartProps> = ({ isFaceDetected, speed, className }) => {
  return <div className={className}>
    {isFaceDetected ?
      <Lottie
        width={'50px'}
        height={'93px'}
        speed={isFaceDetected ? speed : 0}
        options={{ animationData: pulse }}
      />
      :
      null
    }
  </div>
}