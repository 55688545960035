import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from 'core/utils/normalize-blocks'

import { QUIZ_NAMES, HabioFlowStep } from 'src/constants/screens';

const ROUTES = Object.values(QUIZ_NAMES);

const getFlow = (state: any) => state.remoteConfig.flow;
const getSurveyLink = (state: any) => state.remoteConfig.quizSurvey?.link;
const getCausesData = (state: any) => state.quiz.selectedAnswers[QUIZ_NAMES.QUIZ_CAUSES_TO_STRUGGLE];
const getAfter30DaysData = (state: any) => state.quiz.selectedAnswers[QUIZ_NAMES.QUIZ_AFTER_30_DAYS];
const getAfterMonthData = (state: any) => state.quiz.selectedAnswers[QUIZ_NAMES.QUIZ_AFTER_MONTH];

export const selectFlowBlocks = createSelector(
  [getFlow, getSurveyLink, getCausesData, getAfter30DaysData, getAfterMonthData],
  (flow, surveyLink, causes, afterDaysGoals, afterMonthGoals) => {
    let r = normalizeBlocks(flow);

    const isExcludTransitionReviewFromCauses = !causes || causes.includes('none');
    const concatData = afterDaysGoals || [].concat(afterMonthGoals || []);
    const isExcludTransitionReviewFromAfterMonth = (!afterDaysGoals && !afterMonthGoals) || (concatData.length === 1 && concatData.includes('other'));

    const excludSteps: string[] = [];

    if (!surveyLink) {
      excludSteps.push(QUIZ_NAMES.QUIZ_SURVEY);
    }

    if (isExcludTransitionReviewFromCauses) {
      excludSteps.push(QUIZ_NAMES.QUIZ_TRANSITION_REVIEW_FROM_CAUSES);
    }

    if (isExcludTransitionReviewFromAfterMonth) {
      excludSteps.push(QUIZ_NAMES.QUIZ_TRANSITION_REVIEW_FROM_AFTER_MONTH);
    }

    r = r.reduce((acc: string[][], subArray) => {
      const filteredSubArray = subArray.filter(item => !excludSteps.includes(item));
      const intersection = _intersection(filteredSubArray, ROUTES);
      if (intersection.length) {
        acc.push(intersection);
      }
      return acc;
    }, []);

    return r;
  });

export const selectFlow = createSelector([selectFlowBlocks], (flow) => {
  const flatFlow = flow.flat();
  return flatFlow as HabioFlowStep[];
});
