import UAParser from 'ua-parser-js';

import { PREFIX_WITH_PAYMENT } from 'core/constants/general';

export const withPP = (type) => type.indexOf(PREFIX_WITH_PAYMENT) >= 0;

export const getProjectWithoutPrefix = (type) => {
  const isWithPP = withPP(type);

  if (isWithPP) {
    const index = type.indexOf(PREFIX_WITH_PAYMENT);
    return type.slice(0, index);
  }

  return type;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const checkIsAndroidTikTokBrowser = () => {
  const parser = new UAParser();
  const browser = parser.getBrowser();
  const os = parser.getOS();
  return browser?.name === 'TikTok' && os?.name === 'Android';
}