import { setPaymentSystem, setPublicKeys } from './utils/storage';

import {
  addRecurlyDependencies,
  addStripeDependencies,
  addSolidgateDependencies,
  addPaddleDependencies,
} from './utils/external-deps';
import { PaymentSystem, } from './constants';

export { default as Provider } from './components/provider';
export { default as CardForm } from './components/card-form';
export { default as CardElement } from './components/card-element';
export { ApplePayButton } from './components/apple-pay-button';
export { SolidgatePayPalButton, type SolidgatePayPalOrder } from './solidgate/PayPal';
export { default as useApplePay } from './hooks/use-apple-pay';
export { useGooglePay } from './hooks/use-google-pay';
export { default as mappingPricesToProducts } from './utils/prices';
export { getPublicKeys } from './utils/storage';
export { PaymentSystem, ModeGooglePay } from './constants';
export type { ProductInfo } from './constants';

const ReactBilling = {
  async init(paymentSystem: PaymentSystem, keys: any) {
    try {
      if (!paymentSystem || !keys) {
        throw new Error('There is no paymentSystem or no keys');
      }

      setPaymentSystem(paymentSystem);
      setPublicKeys(keys);

      return Promise.all([
        paymentSystem === PaymentSystem.RECURLY
          ? addRecurlyDependencies()
          : paymentSystem === PaymentSystem.STRIPE
            ? addStripeDependencies()
            : paymentSystem === PaymentSystem.SOLIDGATE
              ? addSolidgateDependencies()
              : paymentSystem === PaymentSystem.PADDLE
                ? Promise.all([
                  addPaddleDependencies(),
                  addSolidgateDependencies()
                ])
                : Promise.reject(Error('Unsupported payment system')),
      ]);
    } catch (error) {
      console.log('[INIT ERROR]:', error);
      throw error;
    }
  },
};

export default ReactBilling;
