import React, { useEffect } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { Title, Button, Text } from '@web-solutions/core/ui-elements';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { useHeight } from '@web-solutions/core/hooks/use-height';

import { goAppLink, useSliceDispatch } from '../../slice';

import { t, TM } from '../../../localization';

import { ReactComponent as IconClose } from '../../components/icons/close-icon.svg';

import { useManageContext } from '../../hooks/use-manage-context';

import classes from './style.module.scss';

const tKey = 'manage.free_gift_cancel';

const category = 'manage_free_gift_cancel';

export const FreeGiftCancelPage: React.FC = () => {
  const { mngFreeGiftCancelPage } = useRemoteConfig();
  const { freeGiftCancelContent } = useManageContext();
  const dispatch = useSliceDispatch();
  const containerHeight = useHeight();

  useEffect(() => {
    Analytics.trackEvent(category, EVENT_ACTION.OPEN);
  }, []);

  const handleCloseClick = () => {
    Analytics.flush();
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    dispatch(goAppLink());
  };

  return (
    <div className={classes.wrap} style={{ minHeight: containerHeight }}>
      <div>
        <Title level="h1" className={classes.title}>
          <TM
            k={mngFreeGiftCancelPage?.title}
            defaultKey={`${tKey}.title`}
          />
        </Title>
        <Text className={classes.text}>
          <TM
            k={mngFreeGiftCancelPage?.subtitle}
            defaultKey={`${tKey}.subtitle`}
            components={{ span: <span className={classes.dark} /> }}
          />
        </Text>
      </div>
      <button type="button" onClick={handleCloseClick} className={classes.close}>
        <IconClose width="16px" height="16px" />
      </button>
      {freeGiftCancelContent}
      <Text className={classNames(classes.text, classes.dark)}>{t(`${tKey}.footnote`)}</Text>
      <Button
        onClick={handleCloseClick}
        type="button"
        className={classes.bottom_button}
        title={t(`${tKey}.button_title`)}
      />
    </div>
  );
};
